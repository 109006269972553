import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import nookies from 'nookies';

import jwtDecode from 'jwt-decode';

import { getImages, getUserInfoDetail, decodeTokenProvision } from '../../services/api/api';
import {
  AUTH0_SCOPE,
  AUTH_TOKEN_KEY,
  B2B,
  CUSTOMER_UID,
  GUEST_DATA,
  REDIRECT_URL,
} from '../../constants/index';
import { authTokenCookiOptions, deleteCookie, getDomainForCookies } from '../../lib/utils';
import { isPastDate } from '../../utils/Utils';

import useAuthenticationState from '../../hooks/useAuthenticationState';
import { useAppContext } from '../../lib/contextProviders/contexts/appContext';

export const CustomiseVoucherContext = React.createContext();

export const destroyeeAllNookies = () => {
  nookies.destroy({}, REDIRECT_URL);
  nookies.destroy({}, GUEST_DATA);
  nookies.destroy({}, CUSTOMER_UID);

  // clear auth cookie
  const domain = getDomainForCookies();
  nookies.destroy({}, AUTH_TOKEN_KEY, authTokenCookiOptions(domain));
  nookies.destroy({}, AUTH_TOKEN_KEY);
  deleteCookie(AUTH_TOKEN_KEY);
  deleteCookie(GUEST_DATA);
};

const getImagesFromApi = async (setVoucherMetaData, setBannerImage, isB2bEnabled) => {
  const userRType = isB2bEnabled ? 'b2b' : 'b2c';
  const data = await getImages();
  const filteredDAta = data.find((d) => d[userRType])[userRType] || [];
  const getBannerImg = data.find(
    (img) => img?.type === 'banner' && !isPastDate(img?.end, true) && isPastDate(img?.start, true)
  );
  setBannerImage(getBannerImg?.png);
  setVoucherMetaData(filteredDAta);
};

const getUserId = (tokenValue, setUserId) => {
  const decodedToken = jwtDecode(tokenValue);
  const id = decodedToken['https://claims.identity.nedigital.sg/association/fairprice'];
  setUserId(id);
};

const GlobalContextProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { [AUTH_TOKEN_KEY]: authTokenFromCookie } = nookies.get();
  const [token, setToken] = useState(authTokenFromCookie || null);
  const [paymentError, setPaymentError] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [userId, setUserId] = useState(undefined);
  const [isEditMode, setIsEditMode] = useState(false);
  const [voucherMetaData, setVoucherMetaData] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState();
  const [banerImage, setBannerImage] = useState('');
  const [selectedMethod, setSelectedMethod] = React.useState(null);
  const [selectedCardDefault, setSelectedCardDefault] = useState({});

  const {
    isConfirmedAuth0Authenticated,
    isConfirmedAuth0NotAuthenticated,
    isConfirmedLoggedIn,
    isConfirmedNotLoggedIn,
    isLoggingIn,
  } = useAuthenticationState();
  const {
    appState: { siteType },
  } = useAppContext();
  const isB2BSite = siteType === B2B;

  const getAccessToken = useCallback(() => {
    return getAccessTokenSilently({
      audience: process.env.AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE,
    });
  }, [getAccessTokenSilently]);

  const getAccessRefreshToken = useCallback(() => {
    return getAccessTokenSilently({
      audience: process.env.AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE,
      ignoreCache: true,
    });
  }, [getAccessTokenSilently]);

  const setTokenAuth0 = useCallback(async () => {
    let aToken = await getAccessToken();
    const decodedToken = jwtDecode(aToken);
    const id = decodedToken['https://claims.identity.nedigital.sg/association/fairprice'];
    if (!id) {
      await decodeTokenProvision(aToken);
      aToken = await getAccessRefreshToken();
    }
    setToken(aToken);
  }, [getAccessRefreshToken, getAccessToken]);

  const getUserInfo = useCallback(async (aToken) => {
    const data = await getUserInfoDetail(aToken);
    setUserInfo(data);
    getUserId(aToken, setUserId);
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes('/voucher')) {
      if (!isLoggingIn) {
        getImagesFromApi(setVoucherMetaData, setBannerImage, isB2BSite);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isB2BSite, isLoggingIn, isConfirmedLoggedIn, isConfirmedNotLoggedIn]);

  useEffect(() => {
    if (isConfirmedAuth0Authenticated) {
      setTokenAuth0();
    }
  }, [isConfirmedAuth0Authenticated, setTokenAuth0]);

  useEffect(() => {
    if (token !== null) {
      getUserId(token, setUserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!window.location.pathname.includes('/voucher')) {
      if (isConfirmedAuth0NotAuthenticated && authTokenFromCookie) {
        getUserInfo(authTokenFromCookie);
      }
    }
  }, [getUserInfo, authTokenFromCookie, isConfirmedAuth0NotAuthenticated]);

  return (
    <CustomiseVoucherContext.Provider
      value={{
        paymentError,
        setPaymentError,
        setIsEditMode,
        isEditMode,
        destroyeeAllNookies,
        voucherMetaData,
        setVoucherMetaData,
        selectedVoucher,
        setSelectedVoucher,
        user: user || userInfo,
        userId,
        banerImage,
        selectedCardDefault,
        setSelectedCardDefault,
        selectedMethod,
        setSelectedMethod,
      }}
    >
      <>{children}</>
    </CustomiseVoucherContext.Provider>
  );
};
export default GlobalContextProvider;
